<template>
  <v-container>
    <v-btn to="/new-booking" class="px-2"
      ><v-icon>mdi-chevron-left</v-icon>Go back</v-btn
    >

    <InstructionForm />
  </v-container>
</template>

<script>
import InstructionForm from '@/components/instructionForm/InstructionForm';

export default {
  name: 'Instruction',

  components: {
    InstructionForm
  },

  data: () => ({
    //
  }),

  mounted() {
    //
  }
};
</script>

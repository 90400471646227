<template>
  <v-container>
    <v-form>
      <v-row class="text-left">
        <v-col cols="2">
          <label class="data-type-label"
            >Please use the following data to</label
          >
        </v-col>
        <v-col cols="6">
          <v-select
            :items="followingDataTo"
            v-model="instruction.documentTypeId"
            dense
            outlined
          ></v-select>
        </v-col>
      </v-row>

      <v-row class="text-left">
        <v-col cols="6">
          <label>Shipper:</label>
          <v-textarea
            dense
            outlined
            name="shipper"
            v-model="instruction.shipper"
            no-resize
            placeholder="Contact details"
          ></v-textarea>

          <div class="d-flex align-center">
            <label>Consignee:</label>
            <v-checkbox v-model="instruction.consigneeOrder"></v-checkbox>
            <label>To Order</label>
          </div>

          <v-textarea
            dense
            outlined
            name="consignee"
            v-model="instruction.consignee"
            no-resize
          ></v-textarea>
          <div class="d-flex">
            <label>Notify address:</label>
            <v-btn class="ml-auto mr-1" @click="addAddressHandler"
              >Add More</v-btn
            >
            <v-btn @click="removeAddressHandler">Remove</v-btn>
          </div>
          <v-text-field
            v-for="(address, index) in instruction.notifyAddress"
            :key="index"
            class="mt-1"
            outlined
            dense
            placeholder="Notify address"
            v-model="instruction.notifyAddress[index]"
            no-resize
            rows="1"
          ></v-text-field>

          <v-row>
            <v-col cols="8">
              <label>Vessel(s):</label>
              <v-text-field
                name="vessel"
                outlined
                dense
                v-model="instruction.vessel"
              ></v-text-field>
            </v-col>

            <v-col cols="4">
              <label>Voyage-No.:</label>
              <v-text-field
                name="voyageNumber"
                outlined
                dense
                v-model="instruction.voyageNumber"
              ></v-text-field>
            </v-col>
          </v-row>

          <label>Port of loading:</label>
          <v-text-field
            name="loadingPort"
            outlined
            dense
            readonly
            :value="loadingPort"
          ></v-text-field>

          <label>Port of Discharge:</label>
          <v-text-field
            name="dischargePort"
            outlined
            dense
            :value="dischargePort"
            readonly
          ></v-text-field>
        </v-col>

        <v-col cols="6">
          <div class="carrier-container">
            <label>Carrier:</label>
            <div class="d-flex justify-center align-center">
              <h2>Carrier1</h2>
            </div>
          </div>

          <v-row>
            <v-col cols="6">
              <label>Shipment No.:</label>
              <v-text-field
                name="shipmentNumber"
                outlined
                dense
                v-model="instruction.shipmentNumber"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <label>B/L-No.:</label>
              <v-text-field
                name="blNumber"
                outlined
                dense
                v-model="instruction.blNumber"
              ></v-text-field>
            </v-col>
          </v-row>

          <label>Shipper's Export Reference:</label>
          <v-text-field
            name="exportReference"
            dense
            outlined
            v-model="instruction.exportReference"
          ></v-text-field>

          <label>Forwarding Agent Address:</label>
          <v-textarea
            dense
            outlined
            name="agentAdress"
            v-model="instruction.agentAdress"
            no-resize
          ></v-textarea>

          <label>Consignee's Reference:</label>
          <v-text-field
            name="consigneeReference"
            v-model="instruction.consigneeReference"
            dense
            outlined
          ></v-text-field>

          <label>Place of Receipt:</label>
          <v-textarea
            dense
            outlined
            name="receiptPlace"
            v-model="instruction.receiptPlace"
            no-resize
          ></v-textarea>

          <label>Place of Delivery:</label>
          <v-textarea
            dense
            outlined
            name="deliveryPlace"
            v-model="instruction.deliveryPlace"
            no-resize
          ></v-textarea>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row class="text-left justify-space-between">
        <v-col cols="4" class="px-4 d-flex flex-wrap justify-space-between">
          <label class="label">Movement Type:</label>
          <v-select
            :items="shippingServiceType"
            v-model="instruction.shippingServiceType"
            outlined
            dense
          ></v-select>

          <v-select
            :items="containerLoad"
            v-model="instruction.containerLoad"
            outlined
            dense
          ></v-select>
        </v-col>
        <v-col cols="4" class="d-flex flex-column px-4">
          <label>Total Number of Containers:</label>
          <v-text-field
            name="totalNumberOfContainers"
            v-model="instruction.totalNumberOfContainers"
            outlined
            dense
            readonly
          ></v-text-field>
        </v-col>
        <v-col cols="4" class="d-flex flex-column px-4">
          <label>Total Number of Packages (calculated):</label>
          <v-text-field
            name="totalNumberOfPackages"
            v-model="instruction.totalNumberOfPackages"
            outlined
            dense
            readonly
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row class="justify-space-between">
        <v-col cols="4" class="px-4">
          <v-dialog v-model="dialog" persistent max-width="500">
            <template v-slot:activator="{ on }">
              <label>AMS / ACI Self filer:</label>
              <v-checkbox
                hide-details
                class="mt-0"
                v-model="instruction.AMS_ACI_selfFiler"
                v-on="instruction.AMS_ACI_selfFiler ? null : on"
              ></v-checkbox>
            </template>
            <v-card>
              <v-card-text class="pb-0 pt-6">
                <p>
                  As an AMS/ACI Self-filer, you have to your own Houses Bills
                  with AMS/ACI/ By switching your status to a self filer you
                  must enter the AMS SCAC Code /ACI Number Code. Please note
                  this change will delete any House bills that you have
                  configured for this container.
                </p>
                <p>
                  Are you sure you want to change your status to AMS/ACI
                  self-filer?
                </p>
              </v-card-text>
              <v-card-actions class="d-flex justify-space-around">
                <v-btn class="v-btn--contained px-16" @click="dialog = false">
                  Yes
                </v-btn>
                <v-btn class="v-btn--contained px-16" @click="dismissAmsAci">
                  No
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
        <v-col cols="4" class="px-4">
          <label>AMS SCAC Code / ACI Number Code:</label>
          <v-text-field
            placeholder="Code"
            name="AMS_SCAC_code_ACI_NumberCode"
            v-model="instruction.AMS_SCAC_code_ACI_NumberCode"
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col cols="4" class="px-4">
          <label>Tax ID:</label>
          <v-text-field
            placeholder="ID"
            name="taxId"
            v-model="instruction.taxId"
            outlined
            dense
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4" class="px-4">
          <label>Freight Payable At:</label>
          <v-select
            :items="freightPayableAt"
            v-model="instruction.freightPayableAt"
            outlined
            dense
          ></v-select>
        </v-col>
        <v-col cols="4" class="px-4">
          <label>Invoice Reference:</label>
          <v-text-field
            placeholder="Invoice Reference"
            name="invoiceReference"
            v-model="instruction.invoiceReference"
            outlined
            dense
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="px-4">
          <label>Set Charger To:</label>
          <v-radio-group
            v-model="instruction.setChargeTo"
            row
            @change="setChargerHandler"
          >
            <v-radio
              v-for="(button, index) in instructionFormRadio"
              :label="button.text"
              :value="button.text"
              :key="index"
            ></v-radio>
          </v-radio-group>
        </v-col>
      </v-row>
      <div class="setCharger">
        <v-expand-transition>
          <div v-show="expand">
            <v-row>
              <v-col
                class="px-4 py-0"
                cols="3"
                v-for="(item, i) in setChargeToItems.slice(0, 2)"
                :key="i"
              >
                <label>{{ item.text }}:</label>
                <v-radio-group
                  v-model="instruction[item.selector]"
                  row
                  @change="setChargerDetailsHandler"
                >
                  <v-radio
                    v-for="(button, index) in instructionFormRadio.slice(0, 2)"
                    :label="button.text"
                    :value="button.text"
                    :key="index"
                  ></v-radio>
                </v-radio-group>
              </v-col>
              <v-col class="px-4 py-0" cols="6">
                <div
                  class="d-flex"
                  v-for="(item, i) in setChargeToItems.slice(2, 4)"
                  :key="i"
                >
                  <label class="radioLabel">{{ item.text }}:</label>
                  <v-radio-group
                    v-model="instruction[item.selector]"
                    row
                    class="pt-0 mt-0 ml-6"
                    @change="setChargerDetailsHandler"
                  >
                    <v-radio
                      v-for="(button, index) in instructionFormRadio.slice(
                        0,
                        2
                      )"
                      :label="button.text"
                      :value="button.text"
                      :key="index"
                    ></v-radio>
                  </v-radio-group>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-expand-transition>
      </div>
      <v-row>
        <v-col cols="3">
          <label>Document Type:</label>
          <v-select
            :items="documentType"
            v-model="instruction.documentType"
            outlined
            dense
          ></v-select>
        </v-col>
        <v-col
          class="d-flex flex-wrap justify-space-between"
          cols="3"
          v-for="(freightedItem, i) in freightedItems"
          :key="i"
        >
          <label class="label">{{ freightedItem.text }}</label>
          <div
            v-for="(numberItem, i) in numberItems"
            :key="i"
            class="freightedItem"
          >
            <p>{{ numberItem.text }}:</p>
            <v-text-field
              v-model="instruction[freightedItem.selector][numberItem.selector]"
              outlined
              dense
            ></v-text-field>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="3">
          <label>Receive back the Sea Waybill:</label>
          <v-radio-group
            v-model="instruction.receiveSeaWaybillOfLanding"
            row
            class="mt-1"
          >
            <v-radio
              v-for="(item, i) in receiveSeaWaybillOfLandingItems"
              :key="i"
              :label="item.text"
              :value="item.value"
              class="waybillSelect"
            ></v-radio>
          </v-radio-group>
          <label>Receiver Email address of final B/L:</label>
          <v-text-field
            v-model="instruction.receiveEMailAddressOfFinalBL"
            outlined
            dense
            placeholder="Email"
          ></v-text-field>
        </v-col>
        <v-col>
          <label>Remarks</label>
          <v-textarea
            dense
            outlined
            v-model="instruction.remarks"
            rows="7"
            no-resize
          ></v-textarea>
        </v-col>
      </v-row>
      <v-divider></v-divider>

      <div v-for="(container, index) in instruction.containers" :key="index">
        <v-row>
          <v-col cols="2" offset="8">
            <label>Carrier1</label>
          </v-col>
        </v-row>
        <v-divider></v-divider>

        <v-row>
          <v-col cols="3">
            <v-col class="pl-0">
              <label>Container Nos., Seal Nos.,<br />Marks and Nos.</label>
            </v-col>
            <v-divider></v-divider>
            <div>
              <v-row>
                <v-col cols="8" class="pr-0 pb-0">
                  <label>Equipment Type:</label>
                  <v-select
                    :items="equipmentTypes"
                    dense
                    outlined
                    v-model="container.containerType"
                  >
                    <template slot="selection" slot-scope="data">
                      {{ data.item.name }} - {{ data.item.size }}
                    </template>
                    <template slot="item" slot-scope="data">
                      {{ data.item.name }} - {{ data.item.size }}
                    </template></v-select
                  >
                </v-col>
                <v-col cols="4" class="pb-0">
                  <label>Quantity:</label>
                  <v-text-field
                    type="number"
                    min="1"
                    dense
                    outlined
                    hide-details
                    v-model.number="container.quantity"
                    @input="container.quantity = $event !== '' ? +$event : null"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <label>Container Nos:</label>
              <v-text-field
                type="number"
                min="1"
                dense
                outlined
                hide-details
                v-model="container.containerNos"
                readonly
              ></v-text-field>
              <label class="mb-0 mt-7 d-block">Marks and Nos:</label>
              <v-textarea
                class="pt-0 mb-1 mt-0"
                v-model="container.marksAndNo"
                placeholder="Description"
                rows="5"
                no-resize
                dense
                outlined
              ></v-textarea>
              <div v-for="i in 3" :key="i">
                <label>Seal No {{ i }}:</label>
                <v-text-field
                  v-model="container.sealNo[i - 1]"
                  outlined
                  dense
                ></v-text-field>
              </div>
            </div>
          </v-col>
          <v-col cols="9" class="border_right border-left">
            <v-row>
              <v-col cols="9" class="py-0">
                <v-row>
                  <v-col cols="6" class="border_right pb-0">
                    <label>
                      Number and Kind of Package<br />Description of goods
                    </label>
                  </v-col>
                  <v-col cols="6" class="border_right pb-0">
                    <label>
                      Gross Cargo Weight <br />
                      Measurment
                    </label>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="3" class="pb-0">
                <label
                  >Actions:<br />
                  Containers / Cargo
                </label>
              </v-col>
            </v-row>
            <v-row
              v-for="(cargo, cargoIndex) in container.cargos"
              :key="cargoIndex"
            >
              <v-col cols="9" class="py-0">
                <v-row>
                  <v-col cols="6" class="border_right">
                    <v-divider></v-divider>
                    <div class="mt-3">
                      <label>Number of Package:</label>
                      <v-text-field
                        v-model.number="cargo.numberOfPackages"
                        outlined
                        dense
                        type="number"
                        min="1"
                        @input="
                          cargo.numberOfPackages =
                            $event !== '' ? +$event : null
                        "
                      ></v-text-field>
                    </div>

                    <label class="label">
                      Kind of Packages | UN Packing Code:
                    </label>
                    <v-select
                      :items="kindOfPackages_UN_PackingCode"
                      v-model="cargo.kindOfPackages_UN_PackingCode"
                      outlined
                      class="kindOfPackages"
                      dense
                    ></v-select>
                    <label>Kind of Packages print on B/L as:</label>
                    <v-text-field
                      v-model="cargo.kindOfPackagesPrintOnB_L_as"
                      outlined
                      dense
                    ></v-text-field>
                    <label class="mb-0">Description of Goods:</label>
                    <v-textarea
                      v-model="cargo.descriptionOfGoods"
                      placeholder="Description"
                      rows="9"
                      dense
                      outlined
                      no-resize
                    ></v-textarea>
                  </v-col>

                  <v-col cols="6" class="border_right">
                    <v-divider></v-divider>
                    <v-row>
                      <v-col cols="7" class="pb-0 pr-0">
                        <label>Gross Cargo Weight:</label>
                        <v-text-field
                          v-model="cargo.grossCargoWeight"
                          outlined
                          dense
                          type="number"
                          min="0"
                        ></v-text-field>
                      </v-col>
                      <v-col class="pb-0" cols="5">
                        <label>Unit:</label>
                        <v-select
                          :items="unitOfWeight"
                          v-model="cargo.unitOfWeight"
                          outlined
                          dense
                        ></v-select>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="7" class="py-0 pr-0">
                        <label>Measurement:</label>
                        <v-text-field
                          v-model="cargo.measurement"
                          outlined
                          dense
                          type="number"
                          min="1"
                        ></v-text-field>
                      </v-col>
                      <v-col class="py-0" cols="5">
                        <label>Unit:</label>
                        <v-select
                          :items="unitOfCargoesMeasurement"
                          v-model="cargo.unitOfCargoesMeasurement"
                          outlined
                          dense
                        ></v-select>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="d-flex flex-wrap py-0">
                        <label class="label">HS Code:</label>
                        <v-text-field
                          class="hs-code"
                          outlined
                          dense
                          hide-details
                          v-model="cargo.hs.hscode"
                          maxlength="6"
                          @input="searchHSCode($event, index, cargoIndex)"
                          :disabled="cargo.fak"
                        ></v-text-field>
                        <div class="d-flex attention_item">
                          <v-tooltip top content-class="attention_item-message">
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon v-bind="attrs" v-on="on">
                                mdi-alert-outline
                              </v-icon>
                            </template>
                            <span>
                              Please use the Description of Goods for any
                              additional HS Codes.
                            </span>
                          </v-tooltip>
                        </div>
                        <div class="fak-checkbox">
                          <v-checkbox
                            @change="fakHandler(index, cargoIndex)"
                            hide-details
                            v-model="cargo.fak"
                          ></v-checkbox>
                          <label>Freight all Kinds</label>
                        </div>

                        <label class="label">HS Description:</label>
                        <v-textarea
                          rows="2"
                          dense
                          outlined
                          no-resize
                          hide-details
                          v-model="cargo.hs.description"
                          @input="
                            searchHSDescription($event, index, cargoIndex)
                          "
                          :disabled="cargo.fak"
                        ></v-textarea>
                      </v-col>
                    </v-row>
                    <v-row class="mt-4">
                      <v-col>
                        <label class="mt-2">Code list:</label>

                        <v-card outlined dense>
                          <v-list class="overflow-y-auto py-0 hsCodeList">
                            <div
                              v-if="
                                cargo.codeListLoading ||
                                  (cargo.codeList
                                    ? cargo.codeList.length === 0
                                    : false)
                              "
                              class="emptyList"
                            >
                              <div v-if="cargo.codeListLoading">
                                <p>
                                  Please wait
                                </p>
                                <Loader class="loader" />
                              </div>

                              <p v-else>
                                No available data
                              </p>
                            </div>

                            <v-list-item
                              :disabled="cargo.fak"
                              v-else
                              class="px-0 hs-item"
                              v-for="(item, i) in cargo.codeList"
                              :key="i"
                              :class="{ lightGrey: !!(i % 2) }"
                              @click="selectHSCode(index, cargoIndex, item)"
                            >
                              <div class="hs-numbers">
                                <span>{{ item.hscode }} </span>
                              </div>
                              <div>
                                <p class="mb-0 hs-text">
                                  {{ item.description }}
                                </p>
                              </div>
                            </v-list-item>
                          </v-list>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="3" class="d-flex flex-column actions">
                <v-divider></v-divider>
                <div class=" d-flex flex-column mt-3" v-if="cargoIndex === 0">
                  <label>Container</label>
                  <v-btn @click="copyContainerHandler(index)">Copy</v-btn>
                  <v-btn @click="addContainerHandler">Add</v-btn>
                  <v-btn
                    @click="removeContainerHandler(index)"
                    :disabled="instruction.containers.length === 1"
                    >Remove</v-btn
                  >
                  <!-- <v-btn @click="removeAllHandler(index)">Remove All</v-btn> -->
                </div>

                <label class="mt-3">Cargo</label>
                <v-btn @click="copyCargoHandler(index, cargoIndex)">Copy</v-btn>
                <v-btn @click="addCargoHandler(index)">Add</v-btn>
                <v-btn
                  @click="removeCargoHandler(index, cargoIndex)"
                  :disabled="container.cargos.length === 1"
                  >Remove</v-btn
                >

                <div
                  class="mt-auto mb-6 d-flex flex-column"
                  v-if="
                    index === instruction.containers.length - 1 &&
                      cargoIndex ===
                        instruction.containers[index].cargos.length - 1
                  "
                >
                  <label>House Bill</label>
                  <v-btn>Edit</v-btn>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-divider></v-divider>
      </div>
      <v-row>
        <v-btn class="confirm-btn" @click="nextStep"> Next Step</v-btn>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import { INSTRUCTION_EMPTY, instructionFormData } from './utils';
import { mapActions, mapGetters } from 'vuex';
import Loader from '@/components/Loader';
export default {
  name: 'InstructionForm',
  components: { Loader },
  data: () => ({
    ...instructionFormData,
    instruction: JSON.parse(JSON.stringify(INSTRUCTION_EMPTY)),
    expand: false,
    dialog: false,
    codeListLoading: true,
    equipmentTypes: []
    // containers: { type: {}, quantity: '' }
  }),
  async mounted() {
    if (Object.keys(this.getInstructionData).length) {
      this.instruction = { ...this.instruction, ...this.getInstructionData };
      this.instruction.containers.forEach((element) => {
        !element.sealNo ? (element.sealNo = []) : null;
      });
      this.expand = this.instruction.setChargeTo === 'Individually';
      this.equipmentTypes = await this.loadContainerList();
    } else {
      this.$router.push('/new-booking');
    }
  },
  methods: {
    ...mapActions({
      getHSCodeList: 'instructionForm/getHSCodeList',
      setInstructionForm: 'instructionForm/setInstructionForm',
      loadContainerList: 'newBooking/loadContainerList'
    }),
    nextStep() {
      this.setInstructionForm(this.instruction);
      this.$router.push('/summary');
    },
    addAddressHandler() {
      this.instruction.notifyAddress.push('');
    },
    removeAddressHandler() {
      if (this.instruction.notifyAddress.length > 1) {
        this.instruction.notifyAddress.pop();
      }
    },

    copyContainerHandler(index) {
      const itemCopy = JSON.parse(
        JSON.stringify(this.instruction.containers[index])
      );
      this.instruction.containers.splice(index + 1, 0, itemCopy);
    },
    addContainerHandler() {
      this.instruction.containers.push(
        JSON.parse(JSON.stringify(INSTRUCTION_EMPTY.containers[0]))
      );
    },
    removeContainerHandler(index) {
      if (this.instruction.containers.length > 1) {
        this.instruction.containers.splice(index, 1);
      }
    },
    removeAllHandler() {
      this.instruction.containers = JSON.parse(
        JSON.stringify(INSTRUCTION_EMPTY.containers)
      );
    },
    addCargoHandler(index) {
      this.instruction.containers[index].cargos.push(
        JSON.parse(JSON.stringify(INSTRUCTION_EMPTY.containers[0].cargos[0]))
      );
    },
    removeCargoHandler(index, cargoIndex) {
      if (this.instruction.containers[index].cargos.length > 1) {
        this.instruction.containers[index].cargos.splice(cargoIndex, 1);
      }
    },
    copyCargoHandler(index, cargoIndex) {
      const cargoCopy = JSON.parse(
        JSON.stringify(this.instruction.containers[index].cargos[cargoIndex])
      );
      this.instruction.containers[index].cargos.splice(
        cargoIndex + 1,
        0,
        cargoCopy
      );
    },
    searchHSCode(e, index, cargoIndex) {
      const reg = new RegExp(/^\d+$/);
      // TODO - config line wrapping - https://github.com/prettier/prettier/issues/5378
      const currentHSCode = this.instruction.containers[index].cargos[
        cargoIndex
      ].hs.hscode;
      if (!reg.test(e)) {
        const newStr = e.replace(/\D/g, '');
        this.$nextTick(
          () =>
            // TODO - config line wrapping - https://github.com/prettier/prettier/issues/5378
            (this.instruction.containers[index].cargos[
              cargoIndex
            ].hs.hscode = newStr)
        );
      }
      this.instruction.containers[index].cargos[cargoIndex].hs = {
        hscode: currentHSCode
      };
      if (currentHSCode.length >= 2 && reg.test(e)) {
        this.debouncingQuery(index, cargoIndex, currentHSCode);
      }
    },
    searchHSDescription(e, index, cargoIndex) {
      // TODO - config line wrapping - https://github.com/prettier/prettier/issues/5378
      const currentHSDescription = this.instruction.containers[index].cargos[
        cargoIndex
      ].hs.description;
      this.instruction.containers[index].cargos[cargoIndex].hs = {
        description: currentHSDescription
      };
      if (currentHSDescription.length < 2) {
        this.instruction.containers[index].cargos[cargoIndex].codeList = [];
      } else {
        this.debouncingQuery(index, cargoIndex, currentHSDescription);
      }
    },
    debouncingQuery(index, cargoIndex, parametr) {
      // TODO - config line wrapping - https://github.com/prettier/prettier/issues/5378
      this.instruction.containers[index].cargos[
        cargoIndex
      ].codeListLoading = true;
      clearTimeout(this.timerId);
      this.timerId = setTimeout(async () => {
        try {
          const response = await this.getHSCodeList(parametr);
          // TODO - config line wrapping - https://github.com/prettier/prettier/issues/5378
          this.instruction.containers[index].cargos[
            cargoIndex
          ].codeList = response;
        } catch (error) {
          console.log('>>>', error, ' GET error  <<<');
        } finally {
          // TODO - config line wrapping - https://github.com/prettier/prettier/issues/5378
          this.instruction.containers[index].cargos[
            cargoIndex
          ].codeListLoading = false;
          this.$forceUpdate();
        }
      }, 1000);
    },
    setChargerDetailsHandler() {
      if (
        this.instruction.portChargeOrigin ===
          this.instruction.portChargeDestination &&
        this.instruction.selfFreightAdditional ===
          this.instruction.destinationHaulageCharges &&
        this.instruction.portChargeOrigin ===
          this.instruction.selfFreightAdditional
      ) {
        this.instruction.setChargeTo = this.instruction.portChargeOrigin;
      } else {
        this.instruction.setChargeTo = 'Individually';
      }
    },
    setChargerHandler() {
      console.log(this.instruction.setChargeTo);
      if (this.instruction.setChargeTo === 'Individually') {
        this.instruction.portChargeOrigin = '';
        this.instruction.selfFreightAdditional = '';
        this.instruction.portChargeDestination = '';
        this.instruction.destinationHaulageCharges = '';
        this.expand = true;
      } else {
        this.expand = false;
        this.instruction.portChargeOrigin = this.instruction.setChargeTo;
        this.instruction.selfFreightAdditional = this.instruction.setChargeTo;
        this.instruction.portChargeDestination = this.instruction.setChargeTo;
        this.instruction.destinationHaulageCharges = this.instruction.setChargeTo;
      }
    },
    dismissAmsAci() {
      this.instruction.AMS_ACI_selfFiler = false;
      this.dialog = false;
    },
    selectHSCode(index, cargoIndex, item) {
      this.instruction.containers[index].cargos[cargoIndex].hs = { ...item };
    },

    fakHandler(index, cargoIndex) {
      console.log('fak', index, cargoIndex);
      this.instruction.containers[index].cargos[cargoIndex].hs.hscode = '';
      this.instruction.containers[index].cargos[
        cargoIndex
      ].hs.description = this.instruction.containers[index].cargos[cargoIndex]
        .fak
        ? 'Freight all Kinds'
        : '';
    }
  },
  computed: {
    ...mapGetters('instructionForm', ['getInstructionData']),
    totalNumberOfContainers() {
      let counter = 0;
      for (let container in this.instruction.containers) {
        counter += +this.instruction.containers[container].quantity;
      }
      return counter;
    },
    totalNumberOfPackages() {
      let counter = 0;
      for (let container in this.instruction.containers) {
        for (let cargo of this.instruction.containers[container].cargos) {
          counter += +cargo.numberOfPackages;
        }
      }
      return counter;
    },
    loadingPort() {
      return `${this.instruction.loadingPort.country} - ${this.instruction.loadingPort.port}`;
    },
    dischargePort() {
      return `${this.instruction.dischargePort.country} - ${this.instruction.dischargePort.port}`;
    }
  },
  watch: {
    totalNumberOfContainers() {
      this.instruction.totalNumberOfContainers = this.totalNumberOfContainers;
    },
    totalNumberOfPackages() {
      this.instruction.totalNumberOfPackages = this.totalNumberOfPackages;
    }
  }
};
</script>

<style scoped>
@import './instructionForm.module.css';
</style>
